@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: "RieslingRegular";
  src: local("RieslingRegular"),
    url("./fonts/riesling.ttf") format("truetype");
  font-weight: normal;
}


#App {
  /* Give app full page to work with */
  height: 125vw;
}

@media all and (max-device-width: 600px){
  #App {
    height: 216vw;
  }
}  

/* base styles */
* {
  box-sizing: border-box;
  margin: 0;
  font-family: "Quicksand";
  color: #808080;
}

.navbar {
  padding: 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  /*border-bottom: 1px solid #f2f2f2;*/
}
.navbar .menu {
  align-content : center;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: space-between;
  display: flex;
  gap: 0.5rem;
}
.navbar h1 {
  color: #f1356d;
}
.navbar .links {
  /*margin-left: auto;*/
}
.navbar a {
  margin-left: 8px;
  text-decoration: none;
  padding: 4px;
  color: #808080;
}
.navbar .menu a.active {
  color: #e0e0e0;
}
.navbar a:hover {
  color: #ffffff;
}
.content {
  /*max-width: 1200px; */
  /*margin: 40px auto; */
  margin: 0 0 0 0;
  padding: 0px;
}

.mission-statement {
  padding-left: 5vw;
  padding-top: 5vh;
  font-size: 1.75vw;
  width: 90vw;
  height: 56vw;
  color: #ffffff;
  text-align: justify;
  text-justify: inter-word;
}
@media all and (max-device-width: 800px){ /* Move mission statement up and to the right of the hamberger menu */
  .mission-statement {
    padding-left: 72px;
    padding-top: 2vh;
    font-size: 2.5vw;
  }
}

.non-profit {
  padding-top: 1rem;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 1);
  font-size: 1vw; 
  text-align: center;
  text-justify: inter-word;
}

.collaborators {
  height: '100vh';
}
.collaborators-list {
  text-align: center;
  color: #ffffff;
  font-size: 2.5vw;
}
.collaborators-list p {
  color: #ffffff;
}.collaborators-list a {
  text-decoration: none;
  color: #ffffff;
}
.collaborators-list a:hover {
  color: #808080;
}
h1 {
  font-size: 2.5vw;
  color: #ffffff;
  text-align: center;
  padding-top: 1.0rem;
  padding-bottom: 1.5rem;
}

#page-wrap {
  /* text-align: center; */

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 24px;
  left: 20px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #e0e0e0 /* #373a47; */
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

#contact-form {
  position: absolute;
  background: rgba(0,0,0,0.5);
  color: #FFF;
  font-size: 1.5vw;
  padding-top: 7.5vh;
  padding-left: 10vw;
  width: 90vw;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
}

#contact-form input,
textarea {
    width: 100%;
    border: 0;
    padding: 10px 10px;
    box-sizing: border-box;
    margin-bottom: 15px;
}

#contact-form input, select, textarea{
  color: #000000;
}

#contact-form textarea {
    height: 100px;
    resize: vertical;
}

#contact-form button {
    width: 100%;
    padding: 10px;
    border: 0;
    cursor: pointer;
    background: #fca253;
    font-weight: bold;
    color: #FFF;
    font-size: 1.5vw;
    margin-bottom: 15px;
    text-transform: uppercase;
}

#contact-form input[type=select] {
  font-size: 1.5vw;
}

@media all and (max-device-width: 800px){
  #contact-form {
      font-size: 2.25vw;
  }
}

@media all and (max-device-width: 600px){
  #contact-form {
      font-size: 3.0vw;
  }
}

.signature {
  font-family: RieslingRegular;
  text-rendering: optimizeLegibility;
  font-weight: 300;
	font-size: 4.0vw;
}

@media all and (max-device-width: 600px){
  .signature {
      font-size: 6.0vw;
  }
}

#contact-form p {
  margin-bottom: 1.5vw;
}
